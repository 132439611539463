import Vue from 'vue';
import App from './App.vue';
import Amplify from 'aws-amplify';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';

Amplify.configure({
  aws_project_region: 'eu-west-1',
});
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
